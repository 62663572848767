import React from "react";
const EditIcon = ({
  color = "rgb(65, 71, 85)",
  size = 24,
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    
    viewBox="0 0 1024 1024"
   
    p-id="7613"
    width={size}
    height={size}
    {...props}
  >
    <path d="M959.716928 554.950208" fill="#CCCCCC" p-id="7614"></path>
    <path
      d="M939.445232 124.541481 897.003608 82.099856c-23.438828-23.438828-61.439304-23.438828-84.878133 0l-41.11542 41.114396 127.32385 127.32385 41.111327-41.11235C962.889177 185.985902 962.889177 147.980309 939.445232 124.541481z"
      fill={color}
      p-id="7615"
    ></path>
    <path
      d="M889.98475 890.753253 131.8704 890.753253 131.8704 132.639926l483.21542 0L615.08582 66.060555 134.01525 66.060555c-37.957497 0-68.725244 30.767747-68.725244 68.725244l0 755.968477c0 37.957497 30.767747 68.725244 68.725244 68.725244l755.968477 0c37.957497 0 68.725244-30.767747 68.725244-68.725244L958.708971 409.68166l-68.725244 0L889.983727 890.753253z"
      fill={color}
      p-id="7616"
    ></path>
    <path
      d="M854.561983 294.309002 727.238133 166.986175 382.800429 511.419785l0.214894 0.214894L362.102015 633.061216c-2.697435 15.658631 10.937097 29.262464 26.589588 26.529213l121.128755-21.147646 0.305969 0.305969L854.561983 294.309002z"
      fill={color}
      p-id="7617"
    ></path>
  </svg>
);
export default EditIcon;
