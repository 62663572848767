export const BASE_URL =
  window.location.host.indexOf("localhost") < 0
    ? window.location.protocol + "//" + window.location.host
    : "https://www.fbpro.info";

export default {
  GET_CODE: "/api/Employeetable/telms",
  LOGIN: "/api/Employeetable/login",
  UPLOAD: "/api/common/upload",
  USERLIST: "/api/Employeetable/getEmployeetableList",
  SITELIST: "/api/Urlrelation/getUrlrelationList",
  COSTLIST: "/api/Consumptionschedule/getconsumptionScheduleList",
  ADDUSER: "/api/Employeetable/setEmployeetable",
  USER: "/api/Employeetable/getEmployeetable",
  DELETE_USER: "/api/Employeetable/delEmployeetable",
  USERLIST_NOPAGE: "/api/Employeetable/getEmployeetableListN",
  STIELIST_NOPAGE: "/api/Urlrelation/getUrlrelationListN",
  ADDSITE: "/api/Urlrelation/setUrlrelation",
  SITE: "/api/Urlrelation/getUrlrelation",
  DELETE_SITE: "/api/Urlrelation/delUrlrelation",
  ADDCOST: "/api/Consumptionschedule/setconsumptionSchedule",
  COST: "/api/Consumptionschedule/getconsumptionSchedule",
  DELETE_COST: "/api/Consumptionschedule/delconsumptionSchedule",
  GET_CHART: "/api/trend/baobiao",
  BANGDAN: "/api/trend/bandanii",
  CONFIG: "/api/Urlrelation/getUrlrelationUrl",
  ORDER: "/api/Urlorder/getOrderList",
  PIXEL: "/api/Events/groupevent",
  AD:'/api/Events/groupad',
  EDIT_CONFIG:'/api/Urlrelation/setUrlrelationII'
};
