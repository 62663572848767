import React, { Component } from "react";
import "../style/user.scss";
import Pagination from "@material-ui/lab/Pagination";
import AddUserIcon from "../icon/AddUserIcon";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import * as GetUserListAPI from "../apis/GetUserListAPI";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import * as DeleteUserAPI from "../apis/DeleteUserAPI";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 7,
      count: 0,
      userList: [],
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getUserList = this.getUserList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeAddUser = this.routeAddUser.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    this.getUserList(this.state.page, this.state.per_page);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getUserList(page, per_page) {
    this.toggleShow();
    GetUserListAPI.getUserList(page, per_page).then((res) => {
      this.toggleShow();
      var data = res.data.data;
      if (data) {
        var total = data.total;
        console.log(data.list);
        let count = Math.ceil(total / per_page);
        this.setState({
          userList: data.list,
          count,
        });
      }
    }).catch((err)=>{
      this.toggleShow();
    });
  }

  handlePageChange(e, page) {
    this.setState({ page: page }, () => {
      this.getUserList(page, this.state.per_page);
    });
  }

  routeAddUser(id) {
    const { history } = this.props;
    if (id) {
      history.push(`/AddUser/${id}`);
      return;
    }
    history.push(`/AddUser`);
  }

  delete(id) {
    this.toggleShow();
    DeleteUserAPI.deleteUser(id).then((res) => {
      this.toggleShow();
      var code = res.data.code;
      var msg = res.data.msg;
      if (code === 1) {
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: "删除用户成功",
          })
        );
        this.getUserList(this.state.page, this.state.per_page);
        return;
      }
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: msg,
        })
      );
    }).catch((err)=>{
      this.toggleShow();
    });
  }

  render() {
    const { count, page, userList } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="user-page">
          <div className="toolbar">
            <AddUserIcon
              size={35}
              color="#fff"
              className="icon"
              onClick={() => this.routeAddUser("")}
            />
          </div>
          <div className="head">
            <div className="left">
              <div className="name">ACCOUNT</div>
              <div className="password">PASSWORD</div>
              <div className="nickname">TELEGRAM</div>
              <div className="xing">NAME</div>
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {userList.length > 0 ? (
              <React.Fragment>
                {userList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className="name">{item.username}</div>
                        <div className="password">{item.password}</div>
                        <div className="nickname">{item.nickname}</div>
                        <div className="xing">{item.xingming}</div>
                        <div className="time">{item.createtime}</div>
                      </div>
                      <div className="tool">
                        <EditIcon
                          size={25}
                          className="icon"
                          color="#285db1"
                          onClick={() => this.routeAddUser(item.id)}
                        />
                        <DeleteIcon
                          size={25}
                          className="icon"
                          color="#285db1"
                          onClick={() => this.delete(item.id)}
                        />
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            count={count}
            page={page}
            onChange={this.handlePageChange}
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default User;
