import React from "react";

const LogOutIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#000",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="2287"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M570.107 47.258H453.903V572.78h116.199V47.258z m208.86 86.886l-81.316 80.794c92.872 63.457 162.427 173.143 162.427 300.052 0 190.48-156.544 346.286-348.175 346.286-191.427 0-348.176-155.807-348.176-346.286 0-126.91 69.76-236.595 168.31-294.277l-86.994-86.569C129.055 220.713 47.733 359.188 47.733 514.99c0 254.04 209.076 461.752 464.165 461.752 255.298 0 464.368-207.708 464.368-461.752 0.005-155.802-81.31-294.277-197.299-380.846z"
      fill={color}
      p-id="2288"
    ></path>
  </svg>
);

export default LogOutIcon;
