import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const getSiteList = (page, per_page, userid, url) => {
  return api.post(`${ApiEndpointConstants.SITELIST}`, {
    page,
    per_page,
    userid,
    url,
  });
};

export const getSiteListNoPage = (userid) => {
  return api.post(`${ApiEndpointConstants.STIELIST_NOPAGE}`, { userid });
};
