import React from "react";

const SendEmail = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="8491"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M168.095951 86.146009 789.085224 86.146009c57.140395 0 103.499236 46.358841 103.499236 103.498212L892.58446 534.639626c0 10.780531-2.156106 21.022803-5.187139 30.794353l-70.952982-63.205531c-15.566534-15.767102-46.830585-22.842241-71.695902-12.735046l-127.350456-130.383536L858.085738 171.519422 478.591611 376.191918 99.097484 171.519422l240.687758 187.591469L140.738883 562.872644l242.777349-173.372629 95.076402 61.923328 95.141894-61.923328 154.10275 110.100584c-15.565511 12.634762-24.998347 31.80538-24.998347 52.828182l0 35.981493c-39.149649 5.79396-85.304851 19.507286-127.756709 49.727565L168.095951 638.137839c-57.140395 0-103.498212-46.358841-103.498212-103.498212L64.597739 189.644221C64.597739 132.505873 110.955556 86.146009 168.095951 86.146009z"
      fill={color}
      p-id="8492"
    ></path>
    <path
      d="M754.586502 552.428781l0 83.95818c-111.314225 0-258.746042 53.972239-258.746042 329.496409 48.715515-175.798888 114.346281-189.342346 258.746042-189.342346l0 85.304851c0 6.602372 3.637854 12.532431 9.701966 15.057951 2.022053 0.843204 4.178159 1.248434 6.266727 1.248434 4.244674 0 8.42181-1.650593 11.522428-4.783956L955.114608 717.985398c6.333242-6.333242 6.333242-16.71059 0-23.044856L782.078647 540.906353c-4.64888-4.649903-11.724019-6.065136-17.789155-3.538593C758.224356 539.895327 754.586502 545.824363 754.586502 552.428781z"
      fill={color}
      p-id="8493"
    ></path>
  </svg>
);

export default SendEmail;
