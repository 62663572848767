import api from "./API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const getOrderList = (page, per_page, userid, url, states, orderNu) => {
  return api.post(`${ApiEndpointConstants.ORDER}`, {
    page,
    per_page,
    userid,
    url,
    states,
    orderNu,
  });
};
