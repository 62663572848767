import React from "react";

const CountPrice = ({
    viewBox = '0 0 24 24',
    color = '#333',
    size = 24,
    ...props
}) => (
  <svg
    viewBox="0 0 1212 1024"
    p-id="31839"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M863.393684 329.135158c-192.404211 0-348.348632 155.378526-348.348631 347.02821 0 191.703579 155.944421 347.082105 348.321684 347.082106s348.321684-155.378526 348.321684-347.082106c0-191.649684-155.944421-347.028211-348.321684-347.02821z m128.754527 345.896421v54.918737H900.311579v18.32421h91.890526v54.918737h-91.890526v73.216h-55.107368v-73.216h-91.890527v-54.918737h91.890527v-18.32421h-91.890527V675.031579h58.044632l-78.120421-146.432h73.512421l68.931368 135.895579 59.661474-135.895579h74.266947l-68.904421 146.432h51.469474z"
      fill={color}
      p-id="31840"
    ></path>
    <path
      d="M127.164632 108.705684c0 60.038737 181.813895 108.705684 406.096842 108.705684 224.256 0 406.069895-48.666947 406.069894-108.705684C939.331368 48.666947 757.517474 0 533.234526 0c-224.256 0-406.096842 48.666947-406.096842 108.705684zM121.721263 386.694737c-10.159158-13.312-18.593684-38.804211-18.378105-48.020211 0.269474-11.749053 6.898526-40.96 6.898526-40.96-73.485474 18.081684-108.032 41.364211-108.032 66.694737 0 42.738526 117.140211 77.392842 283.971369 93.642105-104.744421-22.285474-133.766737-47.104-164.45979-71.329684z"
      fill={color}
      p-id="31841"
    ></path>
    <path
      d="M504.32 486.588632a1628.645053 1628.645053 0 0 1-99.705263 3.098947c-211.536842 0-385.185684-39.181474-404.237474-89.249684H0v146.458947h0.161684C0 602.381474 261.820632 642.991158 404.210526 642.991158c18.701474 0 37.079579-0.269474 55.107369-0.673684 3.961263-54.460632 19.294316-107.52 45.002105-155.728842z m-6.251789 313.856c-12.773053 0.296421-25.546105 0.458105-38.319158 0.458105-211.536842 0-385.212632-39.208421-404.264421-89.249684h-0.377264v146.458947h0.188632c-0.188632 55.484632 261.658947 96.094316 404.021895 96.094316 46.268632 0 90.677895-1.401263 132.069052-4.176842a393.781895 393.781895 0 0 1-93.318736-149.557895z m41.768421-362.684632c78.470737-100.998737 204.530526-166.534737 346.677894-166.534737 17.111579 0 33.953684 1.077895 50.526316 2.883369V182.056421c-21.557895 49.421474-194.074947 87.956211-403.806316 87.956211-211.536842 0-385.212632-39.181474-404.264421-89.249685h-0.350316v146.458948h0.161685c2.991158 73.216 182.703158 110.618947 404.075789 110.618947 2.344421 0 4.634947-0.053895 6.952421-0.080842z m-81.057685 258.533053l-0.970105-6.736842c-191.514947 18.863158-348.698947-13.365895-348.698947-13.365895 139.964632 67.098947 328.569263 71.733895 354.115368 72.057263a413.911579 413.911579 0 0 1-4.446316-51.954526z"
      fill={color}
      p-id="31842"
    ></path>
  </svg>
);


export default CountPrice;