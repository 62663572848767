import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "../apis/API";

export const getCostList = (page, per_page, userid, url) => {
  return api.post(`${ApiEndpointConstants.COSTLIST}`, {
    page,
    per_page,
    userid,
    url,
  });
};
