import React, { Component } from "react";
import "../../style/common/leftBar.scss";
import logo from "../../image/logo.png";
import ChartIcon from "../../icon/ChartIcon";
import UserIcon from "../../icon/UserIcon";
import cookie from "react-cookies";
import SiteIcon from "../../icon/SiteIcon";
import CostIcon from "../../icon/CostIcon";
import OrderIcon from "../../icon/OrderIcon";

class LeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
    };
    this.routePage = this.routePage.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      this.setState({ type: parseInt(param.user.type) });
    }
  }

  routePage(type) {
    const { history } = this.props;
    console.log(history);
    switch (type) {
      case "home":
        history.push("/Home");
        break;
      case "user":
        history.push("/User");
        break;
      case "site":
        history.push("/Site");
        break;
      case "cost":
        history.push("/Cost");
        break;
      case "order":
        history.push("/Order");
        break;
      default:
        break;
    }
  }

  render() {
    let { location } = this.props;
    const { type } = this.state;
    return (
      <React.Fragment>
        <div className="leftBar">
          <div className="left-box">
            <div className="head-title">
              <img src={logo} alt="" />
              <p>Management</p>
            </div>
            <ul className="tab-list">
              <li
                className={location.pathname === "/Home" ? "active" : ""}
                onClick={() => this.routePage("home")}
              >
                {location.pathname === "/Home" ? (
                  <ChartIcon size={25} className="icon" color="#fff" />
                ) : (
                  <ChartIcon size={25} className="icon" color="#697a8d" />
                )}
                <p>Chart</p>
              </li>
              {type === 2 && (
                <li
                  className={
                    location.pathname === "/User" ||
                    location.pathname.indexOf("/AddUser") >= 0
                      ? "active"
                      : ""
                  }
                  onClick={() => this.routePage("user")}
                >
                  {location.pathname === "/User" ||
                  location.pathname.indexOf("/AddUser") >= 0 ? (
                    <UserIcon size={25} className="icon" color="#fff" />
                  ) : (
                    <UserIcon size={25} className="icon" color="#697a8d" />
                  )}
                  <p>Users</p>
                </li>
              )}
              <li
                className={
                  location.pathname === "/Site" ||
                  location.pathname.indexOf("/AddSite") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("site")}
              >
                {location.pathname === "/Site" ||
                location.pathname.indexOf("/AddSite") >= 0 ? (
                  <SiteIcon size={25} className="icon" color="#fff" />
                ) : (
                  <SiteIcon size={25} className="icon" color="#697a8d" />
                )}
                <p>Sites</p>
              </li>
              <li
                className={
                  location.pathname === "/Cost" ||
                  location.pathname.indexOf("/AddCost") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("cost")}
              >
                {location.pathname === "/Cost" ||
                location.pathname.indexOf("/AddCost") >= 0 ? (
                  <CostIcon size={25} className="icon" color="#fff" />
                ) : (
                  <CostIcon size={25} className="icon" color="#697a8d" />
                )}
                <p>Costs</p>
              </li>
              <li
                className={
                  location.pathname === "/Order" ||
                  location.pathname.indexOf("/AddOrder") >= 0
                    ? "active"
                    : ""
                }
                onClick={() => this.routePage("order")}
              >
                {location.pathname === "/Order" ||
                location.pathname.indexOf("/AddCost") >= 0 ? (
                  <OrderIcon size={25} className="icon" color="#fff" />
                ) : (
                  <OrderIcon size={25} className="icon" color="#697a8d" />
                )}
                <p>Order</p>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LeftBar;
