import React from "react";

const AllowRight = ({
  size = 24,
  color = "#4C4C4C",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="3138"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M581.632 781.824L802.816 563.2H99.328a51.2 51.2 0 0 1 0-102.4h703.488l-221.184-218.624a51.2 51.2 0 0 1 0-72.192 46.592 46.592 0 0 1 68.096 0l310.272 307.2a55.296 55.296 0 0 1 0 74.752l-310.272 307.2a46.592 46.592 0 0 1-68.096 0 51.2 51.2 0 0 1 0-77.312z"
      fill={color}
      p-id="3139"
    ></path>
  </svg>
);

export default AllowRight;