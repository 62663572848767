import React from "react";

const SiteIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="4102"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M292.6 691.9c-98.3 0-173.6-13-207.4-38.2-13.1-9.7-20.6-21.4-22.4-34.6-2.8-20.3 8.4-40.8 33.2-60.9 2.9-2.3 46.1-26.3 53.1-27.9 9.8-2.1 19.3 3.9 21.5 13.6 2.1 9.1-3.1 18.1-11.7 21-5.9 2.6-37.1 19.4-41.3 22.1-12.4 10.1-20 20.6-19.1 27.2 0.6 4.1 4.8 8.1 8.3 10.7 42 31.3 199.1 45.3 418 11.6C797.3 594.4 932.1 516 928 485.7c-1.2-8.7-18.9-27.8-63.7-27.8-10 0-18-8.1-18-18s8.1-18 18-18c62.7 0 95.5 30.6 99.4 58.9 12.3 89.4-252.4 163.4-433.3 191.3-86.6 13.3-168.1 19.8-237.8 19.8zM260.4 472.4c-0.6 0-1.1-0.1-1.7-0.2-4.9-0.9-8.1-5.6-7.2-10.5 3.2-16.9 8.1-33.6 14.6-49.6 1.9-4.6 7.1-6.8 11.7-5 4.6 1.9 6.8 7.1 5 11.7-6 14.9-10.6 30.5-13.6 46.2-0.8 4.4-4.6 7.4-8.8 7.4z m52.5-113.1c-2 0-4-0.7-5.7-2-3.9-3.1-4.5-8.8-1.3-12.7 39.6-49 95.3-82.5 156.8-94.3 4.9-0.9 9.6 2.3 10.5 7.2 0.9 4.9-2.3 9.6-7.1 10.5-57.4 11-109.3 42.2-146.2 87.9-1.8 2.2-4.4 3.4-7 3.4z"
      fill={color}
      p-id="4103"
    ></path>
    <path
      d="M513.1 892.2c-209.7 0-380.4-170.6-380.4-380.4 0-10.2 0.4-20.5 1.2-30.5 1.1-13.7 2.9-27.5 5.5-41.1C173.5 261.3 330.6 131.4 513 131.4c139.9 0 268.1 76.5 334.8 199.6 4.7 8.7 1.5 19.7-7.3 24.4-8.7 4.7-19.7 1.5-24.4-7.3C755.8 236.6 639.6 167.4 513 167.4 348 167.5 205.7 285 174.9 447c-2.3 12.2-4 24.7-5 37.2-0.7 9.1-1.1 18.4-1.1 27.7 0 189.9 154.5 344.3 344.3 344.3s344.3-154.5 344.3-344.3c0-22.9-2.3-45.8-6.7-68.1-2-9.7 4.4-19.2 14.1-21.2S884 427 886 436.7c4.9 24.6 7.4 49.9 7.4 75.2 0.1 209.7-170.5 380.3-380.3 380.3z"
      fill={color}
      p-id="4104"
    ></path>
  </svg>
);

export default SiteIcon;
