import React, { Component } from "react";
import "../style/login.scss";
import * as GetCodeAPI from "../apis/GetCodeAPI";
import * as LoginAPI from "../apis/LoginAPI";
import Loading from "./common/Loading";
import cookie from "react-cookies";
import { setAPIAuthorizationToken } from "../apis/API";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      code: "",
      loadingShow: false,
    };
    this.inputChange = this.inputChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.getCode = this.getCode.bind(this);
    this.login = this.login.bind(this);
  }

  inputChange(e, type) {
    switch (type) {
      case "username":
        this.setState({ username: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      case "code":
        this.setState({ code: e.target.value });
        break;
      default:
        break;
    }
  }

  getCode() {
    let { username } = this.state;
    this.toggleShow();
    GetCodeAPI.getCode(username)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "已发送验证码",
            })
          );
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  login() {
    let { username, password, code } = this.state;
    LoginAPI.login(username, password, code).then((res) => {
      console.log(res);
      var data = res.data.data;
      var time = res.data.time;
      if (data) {
        time = new Date(new Date(time * 1000 + 3 * 24 * 60 * 60 * 1000));
        setAPIAuthorizationToken(data.token);
        cookie.save("token", data, {
          expires: "",
          path: "/",
          secure: true, // 仅在 HTTPS 上传输
          sameSite: "Strict",
        });
        const { history } = this.props;
        history.push("/");
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="login-page">
          <div className="img-bg"></div>
          <div className="login-box">
            <h1>Login</h1>
            <div className="input-row">
              <input
                type="text"
                placeholder="account"
                onChange={(e) => this.inputChange(e, "username")}
              />
            </div>
            <div className="input-row">
              <input
                type="text"
                placeholder="password"
                onChange={(e) => this.inputChange(e, "password")}
              />
            </div>
            <div className="code-row">
              <input
                type="text"
                placeholder="code"
                onChange={(e) => this.inputChange(e, "code")}
              />
              <div className="btn" onClick={this.getCode}>
                Get Code
              </div>
            </div>
            <div className="btn-row">
              <div className="btn" onClick={this.login}>
                Login
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
