import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const submitCost = (
  id,
  payexpenses,
  picture,
  url,
  consumptiontime,
  category,
  userid
) => {
  return api.post(`${ApiEndpointConstants.ADDCOST}`, {
    id,
    payexpenses,
    picture,
    url,
    consumptiontime,
    category,
    userid,
  });
};
