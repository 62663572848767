import React from "react";

const BangDan = ({
  viewBox = "0 0 24 24",
  color = "#424242",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="14480"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M0 1003.52h327.68V552.96H0v450.56z m102.4-143.36v-81.92h81.92v-40.96H102.4v-40.96h122.88V819.2h-81.92v40.96h81.92v40.96H102.4v-40.96z m593.92-266.24v409.6H1024v-409.6H696.32zM921.6 737.28v163.84H798.72v-40.96h81.92V819.2h-81.92v-40.96h81.92v-40.96h-81.92v-40.96H921.6v40.96zM532.48 348.16h348.16L757.76 225.28 880.64 102.4H532.48v-5.673c12.186-7.107 20.48-20.173 20.48-35.287 0-22.63-18.35-40.96-40.96-40.96s-40.96 18.33-40.96 40.96c0 15.114 8.294 28.18 20.48 35.287V471.04H348.16v532.48h327.68V471.04H532.48V348.16z m0-61.44v-61.44h163.84l20.48 20.48 20.48 20.48 20.48 20.48 20.48 20.48H532.48v-20.48z m20.48 573.44v40.96h-81.92v-40.96h20.48V737.28h-20.48v-40.96h61.44v163.84h20.48z"
      fill={color}
      p-id="14481"
    ></path>
  </svg>
);

export default BangDan;
