import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import "../style/addConfig.scss";
import Select from "react-select";
import * as ConfigSiteAPI from "../apis/ConfigSiteAPI";
import * as AddConfigSite from "../apis/AddConfigSite";
import * as GetEmailTemplateAPI from "../apis/GetEmailTemplateAPI";
import cookie from "react-cookies";

class AddConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      url: "",
      id: "",
      amount: "",
      amount_fast: "",
      analytics: "",
      pixel: "",
      tgid: "",
      shareWebsite: "",
      mixpanelToken: "",
      fbqToken: "",
      langlist: "",
      emailFileArr: [],
      emailTemplateId: null,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.select = this.select.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.getFileList = this.getFileList.bind(this);
  }

  componentDidMount() {
    let { location } = this.props;
    this.getFileList()
      .then(() => {
        if (location.pathname.indexOf("/AddConfig/") >= 0) {
          let { emailFileArr, emailTemplateId } = this.state;
          let url = location.pathname.replace("/AddConfig/", "");
          this.toggleShow();
          ConfigSiteAPI.configSite(url)
            .then((res) => {
              this.toggleShow();
              var data = res.data.data;
              if (data) {
                emailFileArr.map((item, index) => {
                  if (item.value === data.emailTemplateId) {
                    return (emailTemplateId = item);
                  }
                });
                this.setState({
                  id: data.id,
                  amount: data.amount,
                  amount_fast: data.amount_fast,
                  pixel: data.pixel,
                  tgid: data.tgid,
                  shareWebsite: data.shareWebsite,
                  analytics: data.analytics,
                  fbqToken: data.fbqToken,
                  url: data.url,
                  mixpanelToken: data.mixpanelToken,
                  langlist: data.langlist,
                  emailTemplateId,
                });
              }
            })
            .catch((err) => {
              this.toggleShow();
            });
        }
      })
      .catch((error) => {
        // 处理 getFileList 可能的错误
        this.getFileList();
      });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  select(e) {
    console.log(e);
    this.setState({ emailTemplateId: e });
  }

  inputChange(e, type) {
    switch (type) {
      case "tgid":
        this.setState({ tgid: e.target.value });
        break;
      case "pixel":
        this.setState({ pixel: e.target.value });
        break;
      case "amount":
        this.setState({ amount: e.target.value });
        break;
      case "amount_fast":
        this.setState({ amount_fast: e.target.value });
        break;
      case "analytics":
        this.setState({ analytics: e.target.value });
        break;
      case "fbqToken":
        this.setState({ fbqToken: e.target.value });
        break;
      case "shareWebsite":
        this.setState({ shareWebsite: e.target.value });
        break;
      case "mixpanelToken":
        this.setState({ mixpanelToken: e.target.value });
        break;
      case "langlist":
        this.setState({ langlist: e.target.value });
        break;
      default:
        break;
    }
  }

  submit() {
    let {
      id,
      url,
      amount,
      amount_fast,
      analytics,
      tgid,
      fbqToken,
      shareWebsite,
      pixel,
      mixpanelToken,
      langlist,
      emailTemplateId,
    } = this.state;

    let params = {
      id,
      url,
      amount,
      amount_fast,
      analytics,
      tgid,
      fbqToken,
      shareWebsite,
      pixel,
      mixpanelToken,
      langlist,
      emailTemplateId: emailTemplateId.value,
    };
    this.toggleShow();
    AddConfigSite.EditConfig(params)
      .then((res) => {
        this.toggleShow();
        console.log(res);
        var code = res.data.code;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "修改成功",
            })
          );
          this.back();
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  getFileList() {
    return GetEmailTemplateAPI.getEmailTemplateList().then((res) => {
      console.log(res);
      var code = res.data.code;
      if (code === 200) {
        var data = res.data.data;
        // data
        let emailFileArr = [];
        data.map((item, index) => {
          console.log(item);
          item = {
            label: item.filename,
            value: item.id,
          };
          emailFileArr.push(item);
        });
        console.log(emailFileArr);
        this.setState({ emailFileArr });
      }
    });
  }

  render() {
    const {
      url,
      amount,
      amount_fast,
      analytics,
      tgid,
      fbqToken,
      shareWebsite,
      pixel,
      mixpanelToken,
      langlist,
      emailFileArr,
      emailTemplateId,
    } = this.state;
    let param = cookie.load("token");
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addConfig-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#285db1"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">Site :</div>
            <input type="text" value={url || ""} readOnly />
          </div>
          <div className="row">
            <div className="title">广告ID:</div>
            <input
              type="text"
              value={pixel || ""}
              onChange={(e) => this.inputChange(e, "pixel")}
            />
          </div>
          <div className="row">
            <div className="title">广告Token:</div>
            <input
              type="text"
              value={fbqToken || ""}
              onChange={(e) => this.inputChange(e, "fbqToken")}
            />
          </div>
          <div className="row">
            <div className="title">谷歌ID:</div>
            <input
              type="text"
              value={analytics || ""}
              onChange={(e) => this.inputChange(e, "analytics")}
            />
          </div>
          <div className="row">
            <div className="title">飞机ID:</div>
            <input
              type="text"
              value={tgid || ""}
              onChange={(e) => this.inputChange(e, "tgid")}
            />
          </div>
          <div className="row">
            <div className="title">mixpanelToken:</div>
            <input
              type="text"
              value={mixpanelToken || ""}
              onChange={(e) => this.inputChange(e, "mixpanelToken")}
            />
          </div>
          <div className="row">
            <div className="title">金额:</div>
            <input
              type="text"
              value={amount || ""}
              onChange={(e) => this.inputChange(e, "amount")}
            />
          </div>
          <div className="row">
            <div className="title">快速金额:</div>
            <input
              type="text"
              value={amount_fast || ""}
              onChange={(e) => this.inputChange(e, "amount_fast")}
            />
          </div>
          <div className="row">
            <div className="title">正常站网址:</div>
            <input
              type="text"
              value={shareWebsite || ""}
              onChange={(e) => this.inputChange(e, "shareWebsite")}
            />
          </div>
          <div className="row">
            <div className="title">追单邮件：</div>
            <Select
              options={emailFileArr}
              value={emailTemplateId || ""}
              onChange={this.select}
              className="control"
              placeholder="Please select template..."
            />
          </div>
          {param.user.type === 2 ? (
            <div className="row">
              <div className="title">语言配置:</div>
              <textarea
                type="text"
                value={langlist || ""}
                onChange={(e) => this.inputChange(e, "langlist")}
              ></textarea>
            </div>
          ) : (
            ""
          )}

          <div className="footer">
            <Submit
              size={40}
              color="#285db1"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddConfig;
