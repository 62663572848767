import React from "react";

const Pixel = ({
  viewBox = "0 0 24 24",
  color = "#555273",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="29924"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M741.248 310.976a165.44 165.44 0 0 0-55.68-6.4H617.536v247.936h69.312a174.08 174.08 0 0 0 50.56-5.824 70.976 70.976 0 0 0 29.12-16.832 104.64 104.64 0 0 0 23.936-40.832 195.392 195.392 0 0 0 8.384-62.784 153.216 153.216 0 0 0-16.832-78.336 85.248 85.248 0 0 0-40.832-36.928z m-433.216 4.544a147.2 147.2 0 0 1-12.352 39.488l-39.488 106.24h106.176l-37.568-100.48a455.36 455.36 0 0 1-16.832-45.312z m507.008 572.416H194.688a33.856 33.856 0 0 0-33.664 33.728v1.92a33.856 33.856 0 0 0 33.664 33.664h620.416a33.856 33.856 0 0 0 33.664-33.664v-1.92a33.472 33.472 0 0 0-33.664-33.664z m33.664-388.544a280.32 280.32 0 0 0 9.664-73.152 237.76 237.76 0 0 0-14.208-86.144 141.376 141.376 0 0 0-42.752-61.504 127.296 127.296 0 0 0-53.056-25.6 316.928 316.928 0 0 0-64-5.184H559.872v361.024h130.176a262.4 262.4 0 0 0 58.24-5.76 140.48 140.48 0 0 0 43.392-18.176 131.84 131.84 0 0 0 33.024-33.024 192.512 192.512 0 0 0 24-52.48z m-429.312 109.44h62.144L333.888 248.128h-51.776L143.488 608.768h58.944l39.616-100.992h137.792l39.552 101.056z m462.656-542.016a111.808 111.808 0 0 1 111.36 111.424v527.68a111.808 111.808 0 0 1-111.36 111.424H142.272A111.36 111.36 0 0 1 30.848 705.92V178.24a111.808 111.808 0 0 1 111.424-111.424h739.456z"
      fill={color}
      p-id="29925"
    ></path>
  </svg>
);

export default Pixel;
