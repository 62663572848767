import React from "react";
const DeleteIcon = ({
  color = "rgb(65, 71, 85)",
  size = 24,
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="5776"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M853.4 333.5H199.8c-3.9 0-7 3.4-6.6 7.3l60.1 630c0 29.3 23.8 53.1 53.1 53.1H747c29.3 0 53.1-23.8 53.1-53.1l60.1-630c0.2-3.9-2.8-7.3-6.8-7.3zM388.8 890.9h-19.5c-13 0-23.6-10.6-23.6-23.6l-24.3-376c0-13 10.6-23.6 23.6-23.6h19.5c13 0 23.6 10.6 23.6 23.6l24.3 376c0 13-10.6 23.6-23.6 23.6z m171.3-23.6c0 13-10.6 23.6-23.6 23.6H517c-13 0-23.6-10.6-23.6-23.6v-376c0-13 10.6-23.6 23.6-23.6h19.5c13 0 23.6 10.6 23.6 23.6v376z m148.3 0c0 13-10.6 23.6-23.6 23.6h-19.5c-13 0-23.6-10.6-23.6-23.6l24.3-376c0-13 10.6-23.6 23.6-23.6h19.5c13 0 23.6 10.6 23.6 23.6l-24.3 376zM869.3 153.2l-705 162c-4 0.9-7.9-1.6-8.8-5.5l-8.8-38.5c-6.8-29.5 11.8-59.2 41.3-66L800 64.5c29.5-6.8 59.2 11.8 66 41.3l8.8 38.5c0.9 4-1.5 8-5.5 8.9zM386.5 127.3c-6.5-27.9 11-56.1 39-62.6l98.2-22.8c27.9-6.5 56.1 11 62.6 39l3.8 16.2 44.3-10.3-3.1-13.3c-11.7-50.4-62.5-82-112.8-70.3L413.6 27.6c-50.4 11.7-82 62.5-70.3 112.8l3 13.1 43.9-10.2-3.7-16z"
      fill={color}
      p-id="5777"
    ></path>
  </svg>
);

export default DeleteIcon;
