import React from "react";
const ChartIcon = ({
  size = 24,
  color = "#9fa4a6",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="17747"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M170.666667 853.333333V56.888889H56.888889v853.333333a56.888889 56.888889 0 0 0 56.888889 56.888889h853.333333v-113.777778H170.666667z"
      fill={color}
      p-id="17748"
    ></path>
    <path
      d="M312.888889 739.555556h56.888889a28.444444 28.444444 0 0 0 28.444444-28.444445v-284.444444a28.444444 28.444444 0 0 0-28.444444-28.444445h-56.888889a28.444444 28.444444 0 0 0-28.444445 28.444445v284.444444a28.444444 28.444444 0 0 0 28.444445 28.444445zM483.555556 739.555556h56.888888a28.444444 28.444444 0 0 0 28.444445-28.444445v-512a28.444444 28.444444 0 0 0-28.444445-28.444444h-56.888888a28.444444 28.444444 0 0 0-28.444445 28.444444v512a28.444444 28.444444 0 0 0 28.444445 28.444445zM654.222222 739.555556h56.888889a28.444444 28.444444 0 0 0 28.444445-28.444445v-170.666667a28.444444 28.444444 0 0 0-28.444445-28.444444h-56.888889a28.444444 28.444444 0 0 0-28.444444 28.444444v170.666667a28.444444 28.444444 0 0 0 28.444444 28.444445zM824.888889 739.555556h56.888889a28.444444 28.444444 0 0 0 28.444444-28.444445v-398.222222a28.444444 28.444444 0 0 0-28.444444-28.444445h-56.888889a28.444444 28.444444 0 0 0-28.444445 28.444445v398.222222a28.444444 28.444444 0 0 0 28.444445 28.444445z"
      fill={color}
      p-id="17749"
    ></path>
  </svg>
);
export default ChartIcon;
