import React from "react";

const CostIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="8328"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M663.232 170.666667C720.938667 170.666667 768 218.005333 768 276.778667V352c0 17.664-13.909333 32-31.082667 32s-31.082667-14.336-31.082666-32v-75.221333A42.368 42.368 0 0 0 663.232 234.666667H147.413333c-23.68 0-42.602667 19.029333-42.602666 42.112v555.776c0 23.082667 18.922667 42.112 42.602666 42.112h202.389334c17.173333 0 31.082667 14.336 31.082666 32s-13.909333 32-31.082666 32H147.434667C89.728 938.666667 42.666667 891.328 42.666667 832.554667V276.778667C42.666667 218.005333 89.728 170.666667 147.434667 170.666667H663.253333z"
      p-id="8329"
      fill={color}
    ></path>
    <path
      d="M405.333333 42.666667c93.312 0 170.666667 75.136 170.666667 170.666666h-60.245333c0-55.296-48.490667-102.4-110.421334-102.4s-110.421333 47.104-110.421333 102.4H234.666667c0-95.530667 77.354667-170.666667 170.666666-170.666666z"
      p-id="8330"
      fill={color}
    ></path>
    <path
      d="M192 320m32 0l362.666667 0q32 0 32 32l0 0q0 32-32 32l-362.666667 0q-32 0-32-32l0 0q0-32 32-32Z"
      p-id="8331"
      fill={color}
    ></path>
    <path
      d="M192 512m32 0l192 0q32 0 32 32l0 0q0 32-32 32l-192 0q-32 0-32-32l0 0q0-32 32-32Z"
      p-id="8332"
      fill={color}
    ></path>
    <path
      d="M192 704m32 0l106.666667 0q32 0 32 32l0 0q0 32-32 32l-106.666667 0q-32 0-32-32l0 0q0-32 32-32Z"
      p-id="8333"
      fill={color}
    ></path>
    <path
      d="M597.333333 654.549333m32 0l170.666667 0q32 0 32 32l0 0q0 32-32 32l-170.666667 0q-32 0-32-32l0 0q0-32 32-32Z"
      p-id="8334"
      fill={color}
    ></path>
    <path
      d="M597.333333 739.882667m32 0l170.666667 0q32 0 32 32l0 0q0 32-32 32l-170.666667 0q-32 0-32-32l0 0q0-32 32-32Z"
      p-id="8335"
      fill={color}
    ></path>
    <path
      d="M746.666667 693.333333v149.333334a32 32 0 0 1-64 0v-149.333334a32 32 0 0 1 64 0z"
      p-id="8336"
      fill={color}
    ></path>
    <path
      d="M680.021333 578.197333l48.512 46.08a29.376 29.376 0 0 1 0.405334 42.218667 31.36 31.36 0 0 1-43.626667 0.405333L636.8 620.8a29.376 29.376 0 0 1-0.405333-42.218667 31.36 31.36 0 0 1 43.626666-0.405333z"
      p-id="8337"
      fill={color}
    ></path>
    <path
      d="M749.312 578.197333L700.8 624.277333a29.376 29.376 0 0 0-0.405333 42.218667 31.36 31.36 0 0 0 43.626666 0.405333L792.533333 620.8a29.376 29.376 0 0 0 0.405334-42.218667 31.36 31.36 0 0 0-43.626667-0.405333z"
      p-id="8338"
      fill={color}
    ></path>
  </svg>
);

export default CostIcon;
