import api from "./API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const addSite = (id, url, category, userid, status) => {
  return api.post(`${ApiEndpointConstants.ADDSITE}`, {
    id,
    url,
    category,
    userid,
    status,
  });
};
