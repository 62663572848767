import api from "./API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const getChart = (datatype, userid, starttime, endtime, url) => {
  return api.post(`${ApiEndpointConstants.GET_CHART}`, {
    datatype,
    userid,
    starttime,
    endtime,
    url,
  });
};
